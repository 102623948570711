import Service from "./Service";
const baseurl = '/api/antecedentes-usuario';
import { isEmpty } from "lodash";
export default {
    /* index(id, id_grupo = '', params = {}) {
        const url = id_grupo == '' ? `${baseurl}/${id}` : `${baseurl}/${id}/${id_grupo}`;
        return Service.get(url, {
            params: params,
        });

    }, */
    index(id, id_grupo = '', pagination = {},search=''){
        let url = id_grupo == '' ? `${baseurl}/${id}` : `${baseurl}/${id}/${id_grupo}?`;
        
        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    getAntecedenteUsuario(params={}){
        return Service.get(`${baseurl}/get-antecedentes-alert`,
        {
            params: params
        });
    }


}