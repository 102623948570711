<template>
    <main>
        <div class="alert alert-danger alert-icon" v-show="antecedentes.length">
            <div class="alert-icon-aside">
                <i class="fa fa-exclamation-triangle  text-danger animate__animated animate__heartBeat animate__infinite"></i> 
            </div>
            <div class="alert-icon-content">
               <div v-for="grupo in antecedentes" :key="grupo.grupo_id">
                    <h6 class="alert-heading">{{ grupo.grupo_nombre }}</h6>
                    <ul>
                        <li class="text-uppercase" v-for="(item,index) in grupo.antecedentes" :key="index">
                           {{ item.nombre }}: {{ item.valor }}
                        </li>
                    </ul>
               </div>
            </div>
        </div>
    </main>
</template>
<script>
import antecedenteUsuarioService from "../../../../services/antecedenteUsuarioService";
import 'animate.css';
export default {
    props:['usuario'],
    data(){
        return{
            antecedentes:{}
        }
    },
    methods:{
        async getAntecedentes(){
            const response = await antecedenteUsuarioService.getAntecedenteUsuario({
                idusuario:this.usuario.id
            });
            this.antecedentes = response.data;
        }
    },
    async created(){
        await this.getAntecedentes();
    },
    watch:{
        'usuario':function(){
            this.getAntecedentes();
        }
    }
    
}
</script>